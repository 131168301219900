import { gql } from "apollo-boost";

const GET_VISA_TYPE = gql`
  query visaType(
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    visaType(filter: $filter, orderBy: $orderBy, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
      }
    }
  }
`;

export { GET_VISA_TYPE };
