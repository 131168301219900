import React, { useState } from "react";
import { Grid, Button, Modal, CircularProgress } from "@material-ui/core/";
import Menu from "../components/Menu";
import GuestForm from "../components/GuestForm";
import "../assets/scss/ReportGuestList.scss";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_VISA_TYPE } from "../queries/VisaQuery";

const ReportGuestList = () => {
  const visaTypeFilter = {
    filter: [],
    orderBy: [
      {
        field: "id",
        order: "ASC",
      },
    ],
    first: 100,
    page: 1,
  };

  const {
    loading: loading_visatype,
    error: error_visatype,
    data: data_visatype,
  } = useQuery(GET_VISA_TYPE, {
    fetchPolicy: "network-only",
    variables: { ...visaTypeFilter },
  });

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to="/hotel/guest-list" />;
  }
  if (loading_visatype) {
    return <div></div>;
  }
  if (error_visatype) return `Error ${error}`;

  return (
    <Grid container>
      <Menu
        title="Report Guest List"
        name="Random Hotel"
        reportLink="/report"
        guestLink={"/hotel/guest-list"}
      />
      <Grid
        container
        style={{
          padding: "40px",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <h2> Upload File</h2>
        </Grid>
        <Grid item xs={12}>
          <div className="upload-file-instructions">
            <span
              className="mm-font"
              style={{
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {
                "ဧည့်စာရင်းသွင်းထားသော Excel ဖိုင်အဆင့်သင့်ရှိနေရန်လိုအပ်သည်။ ဖိုင်ကိုရွေးချယ်ရန် ခလုပ်ကိုနှိပ်ပြီးကွန်ပြူတာထဲရှိဖိုင်စာရင်းမှ ဧည့်စာရင်းပါသော ဖိုင်ကိုရွေးရမည်။ ထို့နောက် UPLOAD REPORT ခလုတ်ကိုနှိတ်ရမည်။"
              }
            </span>
          </div>
        </Grid>

        <Grid item xs={12}>
          <span className="mm-font">{"(၁) ဖိုင်းရွေးရန်နှိပ်ပါ"}</span>
          <div className="upload-file-area">
            <input
              type="file"
              id="guestlist-file"
              name="guestlist-file"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <span className="mm-font">
            {"(၂) ဖိုင်ရွေးပြီးပါက သတင်းပို့ရန်နှိပ်ပါ"}
          </span>
          <Button
            className="upload-button"
            onClick={async () => {
              if (file !== null) {
                setLoading(true);
                setShowModal(true);
                const data = new FormData();
                data.append("file", file);

                await axios
                  .post(
                    "https://moht-be.mmflights.com/api/import/guest",
                    data,
                    {
                      headers: {
                        "Content-Type": file.type,
                      },
                    }
                  )
                  .then(function (response) {
                    setLoading(false);
                  })
                  .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                    setError(true);
                  });
              }
            }}
          >
            UPLOAD REPORT
          </Button>
        </Grid>
        <Grid item xs={12}>
          <h2> Input Form</h2>
        </Grid>
        <Grid item lg={7}>
          <GuestForm
            setShowModal={setShowModal}
            setError={setError}
            visas={data_visatype.visaType.data}
          />
        </Grid>
      </Grid>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        id="report-modal"
      >
        <Grid container className="report-modal-body">
          {loading && <CircularProgress />}
          {error && (
            <Grid item className="error-msg">
              <span>There was an error with your upload.</span>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setError(false);
                }}
              >
                Close
              </Button>
            </Grid>
          )}
          {!error && (
            <Grid item className="error-msg">
              <span>Successfully uploaded.</span>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setRedirect(true);
                }}
              >
                Close
              </Button>
            </Grid>
          )}
        </Grid>
      </Modal>
    </Grid>
  );
};

export default ReportGuestList;
