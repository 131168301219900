import React, { useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  MenuItem,
  Divider,
  Modal,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { COUNTRIES } from "../config/countries";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_GUEST } from "../queries/CreateGuestQuery";
/* 
    FIELDS 
    * = required
    *No. (English)
    *Full Name
    *M/F (only English)
    *Nationality
    *Passport No/ NRIC No
    *Check In Date
    Check Out Date
    *Room No

    Foreigner Only
    *Visa Type
    *Visa No
    *Visa Expiry Date
    *Arrival Date

    Myanmar Only
    Age (English)
    *Father Name
    Occupation
    *Full Address
    */

const GuestForm = ({ setShowModal, setError, visas }) => {
  const MYANMAR_ID = COUNTRIES.filter(
    (country) => country.CountryName === "Myanmar"
  )[0].CountryId;

  const [createGuest] = useMutation(CREATE_GUEST, {
    onCompleted: (res) => {
      setShowModal(true);
    },
    onError: (err) => {
      setError(true);
      setShowModal(true);
      console.log(err);
    },
  });
  const labels = {
    firstName: "First Name",
    lastName: "Last Name",
    gender: "M/F",
    nationality: "Nationality",
    passportNRC: "Passport No./NRIC No.",
    checkInDate: "Check In Date",
    checkOutDate: "Check Out Date",
    roomNo: "Room No",
    visaType: "Visa Type",
    visaNo: "Visa No",
    visaExpiryDate: "Visa Expiry Date",
    arrivalDate: "Arrival Date",
    age: "Age",
    fatherName: "Father Name",
    occupation: "Occupation",
    fullAddress: "Full Address",
    dob: "Date of Birth",
  };
  const mmLabels = {
    firstName: "အမည်",
    lastName: "မိသားစုအမည်",
    gender: "ကျား/မ",
    nationality: "နိုင်ငံသားအမည်",
    passportNRC: "ပတ်စ်ပို့အမှတ်/မှတ်ပုံတင်အမှတ်",
    checkInDate: "ဝင်ရောက်သည့် နေ့ရက်",
    checkOutDate: "ထက်ခွာသည့် နေ့ရက်",
    roomNo: "အခန်းအမှတ်",
    visaType: "ဗီဇာအမျိုးအစား",
    visaNo: "ဗီဇာအမှတ်",
    visaExpiryDate: "ဗီဇာကုန်ဆုံးရက်",
    arrivalDate: "မြန်မာပြည်ရောက်ရှိရက်",
    age: "အသက်",
    fatherName: "အဘအမည်",
    occupation: "အလုပ်အကိုင်",
    fullAddress: "နေရပ်လိပ်စာအပြည့်အစုံ",
    dob: "မွေးသက္ကရာဇ်",
  };

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    nationality: "",
    passportNRC: "",
    dob: new Date(),
    checkInDate: new Date(),
    checkOutDate: new Date(),
    roomNo: "",
    visaType: "",
    visaNo: "",
    visaExpiryDate: new Date(),
    arrivalDate: new Date(),
    age: "",
    fatherName: "",
    occupation: "",
    fullAddress: "",
  });

  const [errors, setErrors] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const handleChangeValues = (name, value) => {
    setValues((prev) => {
      return {
        ...values,
        [name]: value,
      };
    });
  };

  const checkField = (name, value) => {
    let nat = values.nationality;
    if (
      nat === MYANMAR_ID &&
      (name === "firstName" ||
        name === "lastName" ||
        name === "gender" ||
        name === "nationality" ||
        name === "passportNRC" ||
        name === "checkInDate" ||
        name === "roomNo" ||
        name === "fatherName" ||
        name === "fullAddress")
    ) {
      let errs = errors;
      errs[name] = "Required";
      if (value === "") {
        setErrors(errs);
      } else {
        delete errs[name];
        setErrors(errs);
      }
    } else if (
      nat !== MYANMAR_ID &&
      (name === "firstName" ||
        name === "lastName" ||
        name === "gender" ||
        name === "nationality" ||
        name === "passportNRC" ||
        name === "checkInDate" ||
        name === "roomNo" ||
        name === "visaNo" ||
        name === "visaType" ||
        name === "arrivalDate" ||
        name === "visaExpiryDate")
    ) {
      let errs = errors;
      errs[name] = "Required";
      if (value === "") {
        setErrors(errs);
      } else {
        delete errs[name];
        setErrors(errs);
      }
    } else if (
      nat === "" &&
      (name === "firstName" ||
        name === "lastName" ||
        name === "gender" ||
        name === "nationality" ||
        name === "passportNRC" ||
        name === "checkInDate" ||
        name === "roomNo")
    ) {
      let errs = errors;
      errs[name] = "Required";
      if (value === "") {
        setErrors(errs);
      } else {
        delete errs[name];
        setErrors(errs);
      }
    }
    return () => {};
  };

  const handleValidateField = (name) => {
    checkField(name, values[name]);
  };

  const checkProperties = (obj) => {
    if (obj.nationality === MYANMAR_ID) {
      let objToCheck = {
        firstName: obj.firstName,
        lastName: obj.lastName,
        gender: obj.gender,
        nationality: obj.nationality,
        passportNRC: obj.passportNRC,
        checkInDate: obj.checkInDate,
        roomNo: obj.roomNo,
        fatherName: obj.fatherName,
        fullAddress: obj.fullAddress,
      };
      let errors = {};
      for (let property in objToCheck) {
        if (objToCheck[property] === "") {
          errors[property] = "Required";
        }
      }
      setErrors(errors);
      return Object.values(objToCheck).every((x) => x !== "");
    } else if (obj.nationality !== MYANMAR_ID && obj.nationality !== "") {
      let objToCheck = {
        firstName: obj.firstName,
        lastName: obj.lastName,
        gender: obj.gender,
        nationality: obj.nationality,
        passportNRC: obj.passportNRC,
        checkInDate: obj.checkInDate,
        roomNo: obj.roomNo,
        visaType: obj.visaType,
        visaNo: obj.visaNo,
        visaExpiryDate: obj.visaExpiryDate,
        arrivalDate: obj.arrivalDate,
      };
      let errors = {};
      for (let property in objToCheck) {
        if (objToCheck[property] === "") {
          errors[property] = "Required";
        }
      }
      setErrors(errors);
      return Object.values(objToCheck).every((x) => x !== "");
    } else if (obj.nationality === "") {
      let objToCheck = {
        firstName: obj.firstName,
        lastName: obj.lastName,
        gender: obj.gender,
        nationality: obj.nationality,
        passportNRC: obj.passportNRC,
        checkInDate: obj.checkInDate,
        roomNo: obj.roomNo,
      };
      let errors = {};
      for (let property in objToCheck) {
        if (objToCheck[property] === "") {
          errors[property] = "Required";
        }
      }
      setErrors(errors);
      return Object.values(objToCheck).every((x) => x !== "");
    }
  };

  const handleSubmit = (values) => {
    let visaName =
      values.visaType !== ""
        ? visas.filter((visa) => visa.id === values.visaType)[0].name
        : "";

    let input = {
      id: null,
      passenger: {
        title: "-",
        first_name: values.firstName,
        last_name: values.lastName,
        country_id: values.nationality,
        phone_prefix: "-",
        phone: "-",
        email: "-",
        passport: values.nationality !== MYANMAR_ID ? values.passportNRC : "-",
        passport_exp: moment(new Date()).format("YYYY-MM-DD"),
        nrc: values.nationality === MYANMAR_ID ? values.passportNRC : "-",
        date_of_birth: moment(values.dob).format("YYYY-MM-DD"),
        father_name:
          values.nationality === MYANMAR_ID ? values.fatherName : "-",
        occupation: values.nationality === MYANMAR_ID ? values.occupation : "-",
        photo: "-",
        note: "-",
      },
      full_address:
        values.nationality === MYANMAR_ID ? values.fullAddress : "-",
      from_date: moment(values.checkInDate).format("YYYY-MM-DD"),
      to_date: moment(values.checkOutDate).format("YYYY-MM-DD"),
      ref_number: "-",
      note: "-",
      created_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
    if (values.nationality !== MYANMAR_ID) {
      input.visa = {
        id: null,
        name: values.nationality !== MYANMAR_ID ? visaName : null,
        visa_type_id:
          values.nationality !== MYANMAR_ID ? values.visaType : null,
        visa_number: values.nationality !== MYANMAR_ID ? values.visaNo : null,
        visa_expiry:
          values.nationality !== MYANMAR_ID
            ? moment(values.visaExpiryDate).format("YYYY-MM-DD")
            : null,
        arrival_date:
          values.nationality !== MYANMAR_ID
            ? moment(values.arrivalDate).format("YYYY-MM-DD")
            : "-",
        full_address: "-",
      };
    }

    createGuest({
      variables: {
        input: {
          ...input,
        },
      },
    });
  };
  return (
    <>
      <form id="guest-list-form">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12}>
            <h3>Compulsory Fields</h3>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item md={5} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestfirstName" required>
                  {labels.firstName}{" "}
                  <span className="mm-font">{mmLabels.firstName}</span>
                </FormLabel>
                <Input
                  id="guestFirstName"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("firstName", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("firstName");
                  }}
                />
                <FormHelperText
                  error={errors && errors.firstName ? true : false}
                >
                  <span className="mm-font">ဗမာလို့(သို့)ဘိုလို</span> In
                  Burmese/English
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestLastName" required>
                  {labels.lastName}{" "}
                  <span className="mm-font">{mmLabels.lastName}</span>
                </FormLabel>
                <Input
                  id="guestLastName"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("lastName", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("lastName");
                  }}
                />
                <FormHelperText
                  error={errors && errors.lastName ? true : false}
                >
                  <span className="mm-font">ဗမာလို့(သို့)ဘိုလို</span> In
                  Burmese/English
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestGender" required>
                  {labels.gender}{" "}
                  <span className="mm-font">{mmLabels.gender}</span>
                </FormLabel>
                <Input
                  id="guestGender"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("gender", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("gender");
                  }}
                />
                <FormHelperText error={errors && errors.gender ? true : false}>
                  Only M/F <span className="mm-font">သာ</span>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestNationality" required>
                  {labels.nationality}{" "}
                  <span className="mm-font">{mmLabels.nationality}</span>
                </FormLabel>
                <Select
                  id="guestNationality"
                  value={values.nationality}
                  onChange={(e) => {
                    handleChangeValues("nationality", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("nationality");
                  }}
                  disableUnderline
                >
                  {COUNTRIES.map((country) => {
                    return (
                      <MenuItem
                        value={country.CountryId}
                        key={country.CountryId}
                      >
                        {country.CountryName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText
                  error={errors && errors.nationality ? true : false}
                >
                  {errors && errors.nationality ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestPassportNRC" required>
                  {labels.passportNRC}{" "}
                  <span className="mm-font">{mmLabels.passportNRC}</span>
                </FormLabel>
                <Input
                  id="guestPassportNRC"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("passportNRC", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("passportNRC");
                  }}
                />
                <FormHelperText
                  error={errors && errors.passportNRC ? true : false}
                >
                  {errors && errors.passportNRC ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid className="form-grid" container spacing={2}>
            <Grid item md={4} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestDOB" required>
                  {labels.dob} <span className="mm-font">{mmLabels.dob}</span>
                </FormLabel>
                <KeyboardDatePicker
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="guestDOB"
                  value={values.dob}
                  onChange={(date) => {
                    handleChangeValues("dob", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  oklabel="Ok"
                  cancelabel="Cancel"
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestCheckInDate" required>
                  {labels.checkInDate}{" "}
                  <span className="mm-font">{mmLabels.checkInDate}</span>
                </FormLabel>
                <KeyboardDatePicker
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="guestCheckInDate"
                  value={values.checkInDate}
                  onChange={(date) => {
                    handleChangeValues("checkInDate", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  oklabel="Ok"
                  cancelabel="Cancel"
                  onBlur={() => {
                    handleValidateField("checkInDate");
                  }}
                />
                <FormHelperText
                  error={errors && errors.checkInDate ? true : false}
                >
                  {errors && errors.checkInDate ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestCheckOutDate">
                  {labels.checkOutDate}{" "}
                  <span className="mm-font">{mmLabels.checkOutDate}</span>
                </FormLabel>
                <KeyboardDatePicker
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="guestCheckOutDate"
                  value={values.checkOutDate}
                  onChange={(date) => {
                    handleChangeValues("checkOutDate", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  oklabel="Ok"
                  cancellabel="Cancel"
                />
                <FormHelperText>
                  Could be blank{" "}
                  <span className="mm-font">ကွက်လပ်ထားနိုင်</span>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item md={4} xs={12}>
              <FormControl>
                <FormLabel htmlFor="guestRoomNo" required>
                  {labels.roomNo}{" "}
                  <span className="mm-font">{mmLabels.roomNo}</span>
                </FormLabel>
                <Input
                  id="guestRoomNo"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("roomNo", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("roomNo");
                  }}
                />
                <FormHelperText error={errors && errors.roomNo ? true : false}>
                  {errors && errors.roomNo ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <h3>Foreigner Only</h3>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                disabled={values.nationality === MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestVisaType" required>
                  {labels.visaType}{" "}
                  <span className="mm-font">{mmLabels.visaType}</span>
                </FormLabel>
                <Select
                  id="guestVisaType"
                  value={values.visaType}
                  onChange={(e) => {
                    handleChangeValues("visaType", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("visaType");
                  }}
                  disableUnderline
                >
                  {visas.map((visa) => {
                    return (
                      <MenuItem value={visa.id} key={visa.id}>
                        {visa.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText
                  error={errors && errors.visaType ? true : false}
                >
                  {errors && errors.visaType ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                disabled={values.nationality === MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestVisaNo" required>
                  {labels.visaNo}{" "}
                  <span className="mm-font">{mmLabels.visaNo}</span>
                </FormLabel>
                <Input
                  id="guestVisaNo"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("visaNo", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("visaNo");
                  }}
                />
                <FormHelperText error={errors && errors.visaNo ? true : false}>
                  {errors && errors.visaNo ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                disabled={values.nationality === MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestVisaExpiryDate" required>
                  {labels.visaExpiryDate}{" "}
                  <span className="mm-font">{mmLabels.visaExpiryDate}</span>
                </FormLabel>
                <KeyboardDatePicker
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="guestVisaExpiryDate"
                  value={values.visaExpiryDate}
                  onChange={(date) => {
                    handleChangeValues("visaExpiryDate", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    disabled: values.nationality === MYANMAR_ID ? true : false,
                  }}
                  InputAdornmentProps={{
                    disablePointerEvents:
                      values.nationality === MYANMAR_ID ? true : false,
                  }}
                  oklabel="Ok"
                  cancellabel="Cancel"
                  onBlur={() => {
                    handleValidateField("visaExpiryDate");
                  }}
                />
                <FormHelperText
                  error={errors && errors.visaExpiryDate ? true : false}
                >
                  {errors && errors.visaExpiryDate ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                disabled={values.nationality === MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestArrivalDate" required>
                  {labels.arrivalDate}{" "}
                  <span className="mm-font">{mmLabels.arrivalDate}</span>
                </FormLabel>
                <KeyboardDatePicker
                  variant="dialog"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="guestArrivalDate"
                  value={values.arrivalDate}
                  onChange={(date) => {
                    handleChangeValues("arrivalDate", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    disabled: values.nationality === MYANMAR_ID ? true : false,
                  }}
                  InputAdornmentProps={{
                    disablePointerEvents:
                      values.nationality === MYANMAR_ID ? true : false,
                  }}
                  oklabel="Ok"
                  cancellabel="Cancel"
                  onBlur={() => {
                    handleValidateField("arrivalDate");
                  }}
                />
                <FormHelperText
                  error={errors && errors.arrivalDate ? true : false}
                >
                  {errors && errors.arrivalDate ? "Required" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <h3>Myanmar Only</h3>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item md={2} xs={12}>
              <FormControl
                disabled={values.nationality !== MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestAge">
                  {labels.age} <span className="mm-font">{mmLabels.age}</span>
                </FormLabel>
                <Input
                  id="guestAge"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("age", e.target.value);
                  }}
                />
                <FormHelperText>
                  <span className="mm-font">ဗိုလိုဂဏန်း</span>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12}>
              <FormControl
                disabled={values.nationality !== MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestFatherName" required>
                  {labels.fatherName}{" "}
                  <span className="mm-font">{mmLabels.fatherName}</span>
                </FormLabel>
                <Input
                  id="guestFatherName"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("fatherName", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("fatherName");
                  }}
                />
                <FormHelperText
                  error={errors && errors.fatherName ? true : false}
                >
                  <span className="mm-font">မပါမဖြစ်</span>
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12}>
              <FormControl
                disabled={values.nationality !== MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestOccupation">
                  {labels.occupation}{" "}
                  <span className="mm-font">{mmLabels.occupation}</span>
                </FormLabel>
                <Input
                  id="guestOccupation"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("occupation", e.target.value);
                  }}
                />
                <FormHelperText>
                  <span className="mm-font">မပါမဖြစ်</span>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className="form-grid" container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                disabled={values.nationality !== MYANMAR_ID ? true : false}
              >
                <FormLabel htmlFor="guestFullAddress" required>
                  {labels.fullAddress}{" "}
                  <span className="mm-font">{mmLabels.fullAddress}</span>
                </FormLabel>
                <Input
                  id="guestFullAddress"
                  disableUnderline
                  onChange={(e) => {
                    handleChangeValues("fullAddress", e.target.value);
                  }}
                  onBlur={() => {
                    handleValidateField("fullAddress");
                  }}
                />
                <FormHelperText
                  error={errors && errors.fullAddress ? true : false}
                >
                  <span className="mm-font">မပါမဖြစ်</span>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            className="form-grid"
            justify="center"
            alignItems="center"
          >
            <Button
              className="submit-button"
              onClick={() => {
                checkProperties(values) && setShowFormModal(true);
              }}
            >
              Submit Form
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
      <Modal
        open={showFormModal}
        onClose={() => {
          setShowFormModal(false);
        }}
        id="form-modal"
      >
        <Grid container className="form-modal-body">
          <span>Are you sure want to submit?</span>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                setShowFormModal(false);
                handleSubmit(values);
                // setShowModal(true);
              }}
            >
              Yes
            </Button>
            <Button
              className="no-btn"
              onClick={() => {
                setShowFormModal(false);
              }}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default GuestForm;
