import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Select,
  Checkbox,
  Input,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import "../assets/scss/GuestList.scss";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import ReactPaginate from "react-paginate";
import TableLabelToggle from "./TableLabelToggle";

const arr = [
  "Yangon",
  "Mandalay",
  "NayPyiTaw",
  "Ayeyarwady",
  "Bago",
  "Magway",
  "Sagaing",
  "Tanintharyi",
  "Shan State",
  "Kayin State",
  "Mon State",
  "Rakhine State",
  "Chin State",
  "Kachin State",
  "Kayah State",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GuestList = ({
  title,
  count,
  setCount,
  page,
  setPage,
  setFilters,
  setOrderByField,
  setOrderByValue,
  loading,
  data,
}) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [colState, setColState] = useState({});
  const [stateArr, setStateArr] = useState([]);

  const handleChange = (event) => {
    setStateArr(event.target.value);
  };

  const handleStateArr = () => {
    return stateArr.map((val) => {
      return val === "Yangon"
        ? 1
        : val === "Mandalay"
        ? 2
        : val === "NayPyiTaw"
        ? 3
        : val === "Ayeyarwady"
        ? 4
        : val === "Bago"
        ? 5
        : val === "Magway"
        ? 6
        : val === "Sagaing"
        ? 7
        : val === "Tanintharyi"
        ? 8
        : val === "Shan State"
        ? 9
        : val === "Kayin State"
        ? 10
        : val === "Mon State"
        ? 11
        : val === "Rakhine State"
        ? 12
        : val === "Chin State"
        ? 13
        : val === "Kachin State"
        ? 14
        : 15;
    });
  };

  const handleFilter = () => {
    fromDate &&
      toDate &&
      stateArr.length === 0 &&
      setFilters([
        {
          field: "from_date",
          operator: ">=",
          value: moment(fromDate).format("YYYY-MM-DD"),
        },
        {
          field: "to_date",
          operator: "<=",
          value: moment(toDate).format("YYYY-MM-DD"),
        },
      ]);

    fromDate &&
      toDate &&
      stateArr.length > 0 &&
      setFilters([
        {
          field: "from_date",
          operator: ">=",
          value: moment(fromDate).format("YYYY-MM-DD"),
        },
        {
          field: "to_date",
          operator: "<=",
          value: moment(toDate).format("YYYY-MM-DD"),
        },
        {
          field: "company.township.region_id",
          operator: "IN",
          value: `[${handleStateArr()}]`,
        },
      ]);

    !fromDate &&
      !toDate &&
      stateArr.length > 0 &&
      setFilters([
        {
          field: "company.township.region_id",
          operator: "IN",
          value: `[${handleStateArr()}]`,
        },
      ]);
  };

  const handleColSort = (fieldName, stateName) => {
    setOrderByField(fieldName);
    setOrderByValue(
      colState[stateName] && colState[stateName] === "desc" ? "ASC" : "DESC"
    );
  };

  const handlePagePagination = (pagi) => {
    setPage(pagi.selected);
  };

  const handleSizePerPagePagination = (sizePerPage) => {
    setCount(sizePerPage);
  };

  return (
    <>
      <div className="filter-region">
        <Grid container>
          <Grid item md={1} xs={12}>
            <h5>Guests</h5>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item md={2} xs={6}>
              <KeyboardDatePicker
                variant="dialog"
                format="dd/MM/yyyy"
                margin="normal"
                value={fromDate}
                onChange={(date) => setFromDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                oklabel="Ok"
                cancellabel="Cancel"
                emptyLabel="Start Date"
              />
              <span className="mm-font date-filter-text">မှ</span>
            </Grid>
            <Grid item md={2} xs={6}>
              <KeyboardDatePicker
                variant="dialog"
                format="dd/MM/yyyy"
                margin="normal"
                value={toDate}
                onChange={(date) => setToDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                oklabel="Ok"
                cancellabel="Cancel"
                emptyLabel="End Date"
              />
              <span className="mm-font date-filter-text">ထိ</span>
            </Grid>
            {title === "department" && (
              <Grid item md={3} xs={12} className="state-filter">
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  displayEmpty
                  value={stateArr}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span className="state-placeholder">State</span>;
                    }
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  placeholder="End Date"
                >
                  {arr.map((name, key) => (
                    <MenuItem key={key} value={name}>
                      <Checkbox checked={stateArr.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item md={1} xs={4}>
              <Button
                size="small"
                variant="contained"
                className="filter-btn"
                onClick={handleFilter}
              >
                FILTER
              </Button>
            </Grid>
            <Grid item md={2} xs={8}>
              <span className="no-items">
                Number of items:{" "}
                {data && data.paginatorInfo ? data.paginatorInfo.total : ""}
              </span>
            </Grid>
            <Grid item md={11} xs={9}>
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={data ? data.paginatorInfo.lastPage : 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePagePagination}
                containerClassName={"common-pagination mb-0"}
                subContainerClassName={"common pages pagination"}
                activeClassName={"active"}
                forcePage={page}
              />
            </Grid>
            <Grid item md={1} xs={3}>
              <select
                className="paginate-select common-custom-perpage"
                onChange={(e) => handleSizePerPagePagination(e.target.value)}
                value={count}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="4%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Report At <br /> ဧည့်စာရင်းပို့ချိန်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col1"
                />
              </TableCell>
              <TableCell width="4%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Full Name
                      <br />
                      အမည်အပြည့်အစုံ
                    </>
                  }
                  fieldName="created_at"
                  stateName="col2"
                />
              </TableCell>
              <TableCell width="2%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      M/F
                      <br />
                      ကျား/မ
                    </>
                  }
                  fieldName="created_at"
                  stateName="col3"
                />
              </TableCell>
              <TableCell width="3%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Nationality
                      <br />
                      နိုင်ငံသား
                    </>
                  }
                  fieldName="created_at"
                  stateName="col4"
                />
              </TableCell>
              <TableCell width="3%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Home Country
                      <br />
                      မိခင်နိုင်ငံ
                    </>
                  }
                  fieldName="created_at"
                  stateName="col5"
                />
              </TableCell>
              <TableCell width="6%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Passport No/NRIC No
                      <br />
                      ပတ်စ်ပို့အမှတ်/မှတ်ပုံတင်အမှတ်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col6"
                />
              </TableCell>
              {title === "department" && (
                <TableCell width="4%">
                  <TableLabelToggle
                    colState={colState}
                    setColState={setColState}
                    handleColSort={handleColSort}
                    label={
                      <>
                        Hotel Name
                        <br />
                        ဟိုတယ်အမည်
                      </>
                    }
                    fieldName="created_at"
                    stateName="col7"
                  />
                </TableCell>
              )}
              {title === "department" && (
                <TableCell width="4%">
                  <TableLabelToggle
                    colState={colState}
                    setColState={setColState}
                    handleColSort={handleColSort}
                    label={
                      <>
                        State
                        <br />
                        ပြည်နယ်/တိုင်း
                      </>
                    }
                    fieldName="created_at"
                    stateName="col8"
                  />
                </TableCell>
              )}
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Check in Date
                      <br />
                      ဝင်ရောက်သည့်နေ့ရက်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col9"
                />
              </TableCell>
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Check out Date
                      <br />
                      ထွက်ခွါသည့်နေ့ရက်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col10"
                />
              </TableCell>
              <TableCell width="4%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Room No
                      <br />
                      အခန်းအမှတ်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col11"
                />
              </TableCell>
              <TableCell width="2%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Visa Type
                      <br />
                      ဗီဇာအမျိုးအစား
                    </>
                  }
                  fieldName="created_at"
                  stateName="col12"
                />
              </TableCell>
              <TableCell width="2%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Visa No
                      <br />
                      ဗီဇာအမှတ်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col13"
                />
              </TableCell>
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Visa Expiry Date
                      <br />
                      ဗီဇာကုန်ဆုံးရက်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col14"
                />
              </TableCell>
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Arrival Date
                      <br />
                      မြန်မာပြည်ရောက်ရှိရက်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col15"
                />
              </TableCell>
              <TableCell width="2%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Age
                      <br />
                      အသက်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col16"
                />
              </TableCell>
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Father Name
                      <br />
                      အဘအမည်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col17"
                />
              </TableCell>
              <TableCell width="5%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Occupation
                      <br />
                      အလုပ်အကိုင်
                    </>
                  }
                  fieldName="created_at"
                  stateName="col18"
                />
              </TableCell>
              <TableCell width="10%">
                <TableLabelToggle
                  colState={colState}
                  setColState={setColState}
                  handleColSort={handleColSort}
                  label={
                    <>
                      Full Address
                      <br />
                      နေရပ်လိပ်စာ အပြည့်အစုံ
                    </>
                  }
                  fieldName="created_at"
                  stateName="col19"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data &&
              data.data.length > 0 &&
              data.data.map((row, key) => (
                <TableRow key={key}>
                  <TableCell>
                    {row.created_at
                      ? moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(
                          "YYYY-MMM-DD HH:mm"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger
                      ? `${row.passenger.first_name} ${row.passenger.last_name}`
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger
                      ? row.passenger.title === "Female"
                        ? "F"
                        : "M"
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger
                      ? row.passenger.country
                        ? row.passenger.country.name.toUpperCase()
                        : ""
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger
                      ? row.passenger.country
                        ? row.passenger.country.name.toUpperCase()
                        : ""
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger ? row.passenger.passport : ""}
                  </TableCell>
                  {title === "department" && (
                    <TableCell>{row.company ? row.company.name : ""}</TableCell>
                  )}
                  {title === "department" && (
                    <TableCell>
                      {row.company
                        ? row.company.township
                          ? row.company.township.region
                            ? row.company.township.region.name
                            : ""
                          : ""
                        : ""}
                    </TableCell>
                  )}
                  <TableCell>
                    {row.from_date
                      ? moment(row.from_date, "YYYY-MM-DD HH:mm:ss").format(
                          "YYYY-MM-DD"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.to_date
                      ? moment(row.to_date, "YYYY-MM-DD HH:mm:ss").format(
                          "YYYY-MM-DD"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>{row.ref_number || ""}</TableCell>
                  <TableCell>
                    {row.visa
                      ? row.visa.visaType
                        ? row.visa.visaType.name
                        : ""
                      : ""}
                  </TableCell>
                  <TableCell>{row.visa ? row.visa.visa_number : ""}</TableCell>
                  <TableCell>{row.visa ? row.visa.visa_expiry : ""}</TableCell>
                  <TableCell>{row.visa ? row.visa.arrival_date : ""}</TableCell>
                  <TableCell>
                    {row.passenger
                      ? moment().diff(row.passenger.date_of_birth, "years")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger ? row.passenger.father_name : ""}
                  </TableCell>
                  <TableCell>
                    {row.passenger ? row.passenger.occupation : ""}
                  </TableCell>
                  <TableCell>{row.visa ? row.visa.full_address : ""}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <div className="progress">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default GuestList;
