export const COUNTRIES = [
  {
    CountryId: 1,
    CountryName: "Afghanistan",
    CN_CountryName: "阿富汗",
    CountryCode: 93,
    ISOCode: "AF",
    Status: 1,
  },
  {
    CountryId: 2,
    CountryName: "Albania",
    CN_CountryName: "阿尔巴尼亚",
    CountryCode: 355,
    ISOCode: "AL",
    Status: 1,
  },
  {
    CountryId: 3,
    CountryName: "Algeria",
    CN_CountryName: "阿尔及利亚",
    CountryCode: 213,
    ISOCode: "DZ",
    Status: 1,
  },
  {
    CountryId: 4,
    CountryName: "American Samoa",
    CN_CountryName: "美属萨摩亚",
    CountryCode: 1684,
    ISOCode: "AS",
    Status: 1,
  },
  {
    CountryId: 5,
    CountryName: "Andorra",
    CN_CountryName: "安道尔",
    CountryCode: 376,
    ISOCode: "AD",
    Status: 1,
  },
  {
    CountryId: 6,
    CountryName: "Angola",
    CN_CountryName: "安哥拉",
    CountryCode: 244,
    ISOCode: "AO",
    Status: 1,
  },
  {
    CountryId: 7,
    CountryName: "Anguilla",
    CN_CountryName: "安圭拉",
    CountryCode: 1264,
    ISOCode: "AI",
    Status: 1,
  },
  {
    CountryId: 8,
    CountryName: "Antarctica",
    CN_CountryName: "南极洲",
    CountryCode: 672,
    ISOCode: "AQ",
    Status: 1,
  },
  {
    CountryId: 9,
    CountryName: "Antigua and Barbuda",
    CN_CountryName: "安提瓜和巴布达",
    CountryCode: 1268,
    ISOCode: "AG",
    Status: 1,
  },
  {
    CountryId: 10,
    CountryName: "Argentina",
    CN_CountryName: "阿根廷",
    CountryCode: 54,
    ISOCode: "AR",
    Status: 1,
  },
  {
    CountryId: 11,
    CountryName: "Armenia",
    CN_CountryName: "亚美尼亚",
    CountryCode: 374,
    ISOCode: "AM",
    Status: 1,
  },
  {
    CountryId: 12,
    CountryName: "Aruba",
    CN_CountryName: "阿鲁巴",
    CountryCode: 297,
    ISOCode: "AW",
    Status: 1,
  },
  {
    CountryId: 13,
    CountryName: "Australia",
    CN_CountryName: "澳大利亚",
    CountryCode: 61,
    ISOCode: "AU",
    Status: 1,
  },
  {
    CountryId: 14,
    CountryName: "Austria",
    CN_CountryName: "奥地利",
    CountryCode: 43,
    ISOCode: "AT",
    Status: 1,
  },
  {
    CountryId: 15,
    CountryName: "Azerbaijan",
    CN_CountryName: "阿塞拜疆",
    CountryCode: 994,
    ISOCode: "AZ",
    Status: 1,
  },
  {
    CountryId: 16,
    CountryName: "Bahamas",
    CN_CountryName: "巴哈马",
    CountryCode: 1242,
    ISOCode: "BS",
    Status: 1,
  },
  {
    CountryId: 17,
    CountryName: "Bahrain",
    CN_CountryName: "巴林",
    CountryCode: 973,
    ISOCode: "BH",
    Status: 1,
  },
  {
    CountryId: 18,
    CountryName: "Bangladesh",
    CN_CountryName: "孟加拉国",
    CountryCode: 880,
    ISOCode: "BD",
    Status: 1,
  },
  {
    CountryId: 19,
    CountryName: "Barbados",
    CN_CountryName: "巴巴多斯",
    CountryCode: 1246,
    ISOCode: "BB",
    Status: 1,
  },
  {
    CountryId: 20,
    CountryName: "Belarus",
    CN_CountryName: "白俄罗斯",
    CountryCode: 375,
    ISOCode: "BY",
    Status: 1,
  },
  {
    CountryId: 21,
    CountryName: "Belgium",
    CN_CountryName: "比利时",
    CountryCode: 32,
    ISOCode: "BE",
    Status: 1,
  },
  {
    CountryId: 22,
    CountryName: "Belize",
    CN_CountryName: "伯利兹",
    CountryCode: 501,
    ISOCode: "BZ",
    Status: 1,
  },
  {
    CountryId: 23,
    CountryName: "Benin",
    CN_CountryName: "贝宁",
    CountryCode: 229,
    ISOCode: "BJ",
    Status: 1,
  },
  {
    CountryId: 24,
    CountryName: "Bermuda",
    CN_CountryName: "百慕大",
    CountryCode: 1441,
    ISOCode: "BM",
    Status: 1,
  },
  {
    CountryId: 25,
    CountryName: "Bhutan",
    CN_CountryName: "不丹",
    CountryCode: 975,
    ISOCode: "BT",
    Status: 1,
  },
  {
    CountryId: 26,
    CountryName: "Bolivia",
    CN_CountryName: "玻利维亚",
    CountryCode: 591,
    ISOCode: "BO",
    Status: 1,
  },
  {
    CountryId: 27,
    CountryName: "Bosnia and Herzegovina",
    CN_CountryName: "波斯尼亚和黑塞哥维那",
    CountryCode: 387,
    ISOCode: "BA",
    Status: 1,
  },
  {
    CountryId: 28,
    CountryName: "Botswana",
    CN_CountryName: "博茨瓦纳",
    CountryCode: 267,
    ISOCode: "BW",
    Status: 1,
  },
  {
    CountryId: 29,
    CountryName: "Brazil",
    CN_CountryName: "巴西",
    CountryCode: 55,
    ISOCode: "BR",
    Status: 1,
  },
  {
    CountryId: 30,
    CountryName: "British Virgin Islands",
    CN_CountryName: "英属维尔京群岛",
    CountryCode: 1284,
    ISOCode: "VG",
    Status: 1,
  },
  {
    CountryId: 31,
    CountryName: "Brunei",
    CN_CountryName: "文莱",
    CountryCode: 673,
    ISOCode: "BN",
    Status: 1,
  },
  {
    CountryId: 32,
    CountryName: "Bulgaria",
    CN_CountryName: "保加利亚",
    CountryCode: 359,
    ISOCode: "BG",
    Status: 1,
  },
  {
    CountryId: 33,
    CountryName: "Burkina Faso",
    CN_CountryName: "布基纳法索",
    CountryCode: 226,
    ISOCode: "BF",
    Status: 1,
  },
  {
    CountryId: 34,
    CountryName: "Myanmar",
    CN_CountryName: "缅甸",
    CountryCode: 95,
    ISOCode: "MM",
    Status: 1,
  },
  {
    CountryId: 35,
    CountryName: "Burundi",
    CN_CountryName: "布隆迪",
    CountryCode: 257,
    ISOCode: "BI",
    Status: 1,
  },
  {
    CountryId: 36,
    CountryName: "Cambodia",
    CN_CountryName: "柬埔寨",
    CountryCode: 855,
    ISOCode: "KH",
    Status: 1,
  },
  {
    CountryId: 37,
    CountryName: "Cameroon",
    CN_CountryName: "喀麦隆",
    CountryCode: 237,
    ISOCode: "CM",
    Status: 1,
  },
  {
    CountryId: 38,
    CountryName: "Canada",
    CN_CountryName: "加拿大",
    CountryCode: 1,
    ISOCode: "CA",
    Status: 1,
  },
  {
    CountryId: 39,
    CountryName: "Cape Verde",
    CN_CountryName: "佛得角",
    CountryCode: 238,
    ISOCode: "CV",
    Status: 1,
  },
  {
    CountryId: 40,
    CountryName: "Cayman Islands",
    CN_CountryName: "开曼群岛",
    CountryCode: 1345,
    ISOCode: "KY",
    Status: 1,
  },
  {
    CountryId: 41,
    CountryName: "Central African Republic",
    CN_CountryName: "中非共和国",
    CountryCode: 236,
    ISOCode: "CF",
    Status: 1,
  },
  {
    CountryId: 42,
    CountryName: "Chad",
    CN_CountryName: "乍得",
    CountryCode: 235,
    ISOCode: "TD",
    Status: 1,
  },
  {
    CountryId: 43,
    CountryName: "Chile",
    CN_CountryName: "智利",
    CountryCode: 56,
    ISOCode: "CL",
    Status: 1,
  },
  {
    CountryId: 44,
    CountryName: "China",
    CN_CountryName: "中国",
    CountryCode: 86,
    ISOCode: "CN",
    Status: 1,
  },
  {
    CountryId: 45,
    CountryName: "Christmas Island",
    CN_CountryName: "圣诞岛",
    CountryCode: 61,
    ISOCode: "CX",
    Status: 1,
  },
  {
    CountryId: 46,
    CountryName: "Cocos (Keeling) Islands",
    CN_CountryName: "科科斯（基林）群岛",
    CountryCode: 61,
    ISOCode: "CC",
    Status: 1,
  },
  {
    CountryId: 47,
    CountryName: "Colombia",
    CN_CountryName: "哥伦比亚",
    CountryCode: 57,
    ISOCode: "CO",
    Status: 1,
  },
  {
    CountryId: 48,
    CountryName: "Comoros",
    CN_CountryName: "科​​摩罗",
    CountryCode: 269,
    ISOCode: "KM",
    Status: 1,
  },
  {
    CountryId: 49,
    CountryName: "Cook Islands",
    CN_CountryName: "库克群岛",
    CountryCode: 682,
    ISOCode: "CK",
    Status: 1,
  },
  {
    CountryId: 50,
    CountryName: "Costa Rica",
    CN_CountryName: "哥斯达黎加",
    CountryCode: 506,
    ISOCode: "CR",
    Status: 1,
  },
  {
    CountryId: 51,
    CountryName: "Croatia",
    CN_CountryName: "克罗地亚",
    CountryCode: 385,
    ISOCode: "HR",
    Status: 1,
  },
  {
    CountryId: 52,
    CountryName: "Cuba",
    CN_CountryName: "古巴",
    CountryCode: 53,
    ISOCode: "CU",
    Status: 1,
  },
  {
    CountryId: 53,
    CountryName: "Cyprus",
    CN_CountryName: "塞浦路斯",
    CountryCode: 357,
    ISOCode: "CY",
    Status: 1,
  },
  {
    CountryId: 54,
    CountryName: "Czech Republic",
    CN_CountryName: "捷克共和国",
    CountryCode: 420,
    ISOCode: "CZ",
    Status: 1,
  },
  {
    CountryId: 55,
    CountryName: "Democratic Republic of the Congo",
    CN_CountryName: "刚果民主共和国",
    CountryCode: 243,
    ISOCode: "CD",
    Status: 1,
  },
  {
    CountryId: 56,
    CountryName: "Denmark",
    CN_CountryName: "丹麦",
    CountryCode: 45,
    ISOCode: "DK",
    Status: 1,
  },
  {
    CountryId: 57,
    CountryName: "Djibouti",
    CN_CountryName: "吉布提",
    CountryCode: 253,
    ISOCode: "DJ",
    Status: 1,
  },
  {
    CountryId: 58,
    CountryName: "Dominica",
    CN_CountryName: "多米尼加",
    CountryCode: 1767,
    ISOCode: "DM",
    Status: 1,
  },
  {
    CountryId: 59,
    CountryName: "Dominican Republic",
    CN_CountryName: "多米尼加共和国",
    CountryCode: 1809,
    ISOCode: "DO",
    Status: 1,
  },
  {
    CountryId: 60,
    CountryName: "Ecuador",
    CN_CountryName: "厄瓜多尔",
    CountryCode: 593,
    ISOCode: "EC",
    Status: 1,
  },
  {
    CountryId: 61,
    CountryName: "Egypt",
    CN_CountryName: "埃及",
    CountryCode: 20,
    ISOCode: "EG",
    Status: 1,
  },
  {
    CountryId: 62,
    CountryName: "El Salvador",
    CN_CountryName: "萨尔瓦多",
    CountryCode: 503,
    ISOCode: "SV",
    Status: 1,
  },
  {
    CountryId: 63,
    CountryName: "Equatorial Guinea",
    CN_CountryName: "赤道几内亚",
    CountryCode: 240,
    ISOCode: "GQ",
    Status: 1,
  },
  {
    CountryId: 64,
    CountryName: "Eritrea",
    CN_CountryName: "厄立特里亚",
    CountryCode: 291,
    ISOCode: "ER",
    Status: 1,
  },
  {
    CountryId: 65,
    CountryName: "Estonia",
    CN_CountryName: "爱沙尼亚",
    CountryCode: 372,
    ISOCode: "EE",
    Status: 1,
  },
  {
    CountryId: 66,
    CountryName: "Ethiopia",
    CN_CountryName: "埃塞俄比亚",
    CountryCode: 251,
    ISOCode: "ET",
    Status: 1,
  },
  {
    CountryId: 67,
    CountryName: "Falkland Islands",
    CN_CountryName: "福克兰群岛",
    CountryCode: 500,
    ISOCode: "FK",
    Status: 1,
  },
  {
    CountryId: 68,
    CountryName: "Faroe Islands",
    CN_CountryName: "法罗群岛",
    CountryCode: 298,
    ISOCode: "FO",
    Status: 1,
  },
  {
    CountryId: 69,
    CountryName: "Fiji",
    CN_CountryName: "斐济",
    CountryCode: 679,
    ISOCode: "FJ",
    Status: 1,
  },
  {
    CountryId: 70,
    CountryName: "Finland",
    CN_CountryName: "芬兰",
    CountryCode: 358,
    ISOCode: "FI",
    Status: 1,
  },
  {
    CountryId: 71,
    CountryName: "France",
    CN_CountryName: "法国",
    CountryCode: 33,
    ISOCode: "FR",
    Status: 1,
  },
  {
    CountryId: 72,
    CountryName: "French Polynesia",
    CN_CountryName: "法属波利尼西亚",
    CountryCode: 689,
    ISOCode: "PF",
    Status: 1,
  },
  {
    CountryId: 73,
    CountryName: "Gabon",
    CN_CountryName: "加蓬",
    CountryCode: 241,
    ISOCode: "GA",
    Status: 1,
  },
  {
    CountryId: 74,
    CountryName: "Gambia",
    CN_CountryName: "冈比亚",
    CountryCode: 220,
    ISOCode: "GM",
    Status: 1,
  },
  {
    CountryId: 75,
    CountryName: "Gaza Strip",
    CN_CountryName: "加沙地带",
    CountryCode: 970,
    ISOCode: "PS",
    Status: 1,
  },
  {
    CountryId: 76,
    CountryName: "Georgia",
    CN_CountryName: "格鲁吉亚",
    CountryCode: 995,
    ISOCode: "GE",
    Status: 1,
  },
  {
    CountryId: 77,
    CountryName: "Germany",
    CN_CountryName: "德国",
    CountryCode: 49,
    ISOCode: "DE",
    Status: 1,
  },
  {
    CountryId: 78,
    CountryName: "Ghana",
    CN_CountryName: "加纳",
    CountryCode: 233,
    ISOCode: "GH",
    Status: 1,
  },
  {
    CountryId: 79,
    CountryName: "Gibraltar",
    CN_CountryName: "直布罗陀",
    CountryCode: 350,
    ISOCode: "GI",
    Status: 1,
  },
  {
    CountryId: 80,
    CountryName: "Greece",
    CN_CountryName: "希腊",
    CountryCode: 30,
    ISOCode: "GR",
    Status: 1,
  },
  {
    CountryId: 81,
    CountryName: "Greenland",
    CN_CountryName: "格林兰",
    CountryCode: 299,
    ISOCode: "GL",
    Status: 1,
  },
  {
    CountryId: 82,
    CountryName: "Grenada",
    CN_CountryName: "格林纳达",
    CountryCode: 1473,
    ISOCode: "GD",
    Status: 1,
  },
  {
    CountryId: 83,
    CountryName: "Guam",
    CN_CountryName: "关岛",
    CountryCode: 1671,
    ISOCode: "GU",
    Status: 1,
  },
  {
    CountryId: 84,
    CountryName: "Guatemala",
    CN_CountryName: "危地马拉",
    CountryCode: 502,
    ISOCode: "GT",
    Status: 1,
  },
  {
    CountryId: 85,
    CountryName: "Guinea",
    CN_CountryName: "几内亚",
    CountryCode: 224,
    ISOCode: "GN",
    Status: 1,
  },
  {
    CountryId: 86,
    CountryName: "Guinea-Bissau",
    CN_CountryName: "几内亚比绍",
    CountryCode: 245,
    ISOCode: "GW",
    Status: 1,
  },
  {
    CountryId: 87,
    CountryName: "Guyana",
    CN_CountryName: "圭亚那",
    CountryCode: 592,
    ISOCode: "GY",
    Status: 1,
  },
  {
    CountryId: 88,
    CountryName: "Haiti",
    CN_CountryName: "海地",
    CountryCode: 509,
    ISOCode: "HT",
    Status: 1,
  },
  {
    CountryId: 89,
    CountryName: "Holy See (Vatican City)",
    CN_CountryName: "教廷（梵蒂冈城）",
    CountryCode: 39,
    ISOCode: "VA",
    Status: 1,
  },
  {
    CountryId: 90,
    CountryName: "Honduras",
    CN_CountryName: "洪都拉斯",
    CountryCode: 504,
    ISOCode: "HN",
    Status: 1,
  },
  {
    CountryId: 91,
    CountryName: "Hong Kong",
    CN_CountryName: "香港",
    CountryCode: 852,
    ISOCode: "HK",
    Status: 1,
  },
  {
    CountryId: 92,
    CountryName: "Hungary",
    CN_CountryName: "匈牙利",
    CountryCode: 36,
    ISOCode: "HU",
    Status: 1,
  },
  {
    CountryId: 93,
    CountryName: "Iceland",
    CN_CountryName: "冰岛",
    CountryCode: 354,
    ISOCode: "IS",
    Status: 1,
  },
  {
    CountryId: 94,
    CountryName: "India",
    CN_CountryName: "印度",
    CountryCode: 91,
    ISOCode: "IN",
    Status: 1,
  },
  {
    CountryId: 95,
    CountryName: "Indonesia",
    CN_CountryName: "印度尼西亚",
    CountryCode: 62,
    ISOCode: "ID",
    Status: 1,
  },
  {
    CountryId: 96,
    CountryName: "Iran",
    CN_CountryName: "伊朗",
    CountryCode: 98,
    ISOCode: "IR",
    Status: 1,
  },
  {
    CountryId: 97,
    CountryName: "Iraq",
    CN_CountryName: "伊拉克",
    CountryCode: 964,
    ISOCode: "IQ",
    Status: 1,
  },
  {
    CountryId: 98,
    CountryName: "Ireland",
    CN_CountryName: "爱尔兰",
    CountryCode: 353,
    ISOCode: "IE",
    Status: 1,
  },
  {
    CountryId: 99,
    CountryName: "Isle of Man",
    CN_CountryName: "马恩岛",
    CountryCode: 44,
    ISOCode: "IM",
    Status: 1,
  },
  {
    CountryId: 100,
    CountryName: "Israel",
    CN_CountryName: "以色列",
    CountryCode: 972,
    ISOCode: "IL",
    Status: 1,
  },
  {
    CountryId: 101,
    CountryName: "Italy",
    CN_CountryName: "意大利",
    CountryCode: 39,
    ISOCode: "IT",
    Status: 1,
  },
  {
    CountryId: 102,
    CountryName: "Ivory Coast",
    CN_CountryName: "象牙海岸",
    CountryCode: 225,
    ISOCode: "CI",
    Status: 1,
  },
  {
    CountryId: 103,
    CountryName: "Jamaica",
    CN_CountryName: "牙买加",
    CountryCode: 1876,
    ISOCode: "JM",
    Status: 1,
  },
  {
    CountryId: 104,
    CountryName: "Japan",
    CN_CountryName: "日本",
    CountryCode: 81,
    ISOCode: "JP",
    Status: 1,
  },
  {
    CountryId: 105,
    CountryName: "Jordan",
    CN_CountryName: "约旦",
    CountryCode: 962,
    ISOCode: "JO",
    Status: 1,
  },
  {
    CountryId: 106,
    CountryName: "Kazakhstan",
    CN_CountryName: "哈萨克斯坦",
    CountryCode: 7,
    ISOCode: "KZ",
    Status: 1,
  },
  {
    CountryId: 107,
    CountryName: "Kenya",
    CN_CountryName: "肯尼亚",
    CountryCode: 254,
    ISOCode: "KE",
    Status: 1,
  },
  {
    CountryId: 108,
    CountryName: "Kiribati",
    CN_CountryName: "基里巴斯",
    CountryCode: 686,
    ISOCode: "KI",
    Status: 1,
  },
  {
    CountryId: 109,
    CountryName: "Kosovo",
    CN_CountryName: "科索沃",
    CountryCode: 381,
    ISOCode: "XK",
    Status: 1,
  },
  {
    CountryId: 110,
    CountryName: "Kuwait",
    CN_CountryName: "科威特",
    CountryCode: 965,
    ISOCode: "KW",
    Status: 1,
  },
  {
    CountryId: 111,
    CountryName: "Kyrgyzstan",
    CN_CountryName: "吉尔吉斯斯坦",
    CountryCode: 996,
    ISOCode: "KG",
    Status: 1,
  },
  {
    CountryId: 112,
    CountryName: "Laos",
    CN_CountryName: "老挝",
    CountryCode: 856,
    ISOCode: "LA",
    Status: 1,
  },
  {
    CountryId: 113,
    CountryName: "Latvia",
    CN_CountryName: "拉脱维亚",
    CountryCode: 371,
    ISOCode: "LV",
    Status: 1,
  },
  {
    CountryId: 114,
    CountryName: "Lebanon",
    CN_CountryName: "黎巴嫩",
    CountryCode: 961,
    ISOCode: "LB",
    Status: 1,
  },
  {
    CountryId: 115,
    CountryName: "Lesotho",
    CN_CountryName: "莱索托",
    CountryCode: 266,
    ISOCode: "LS",
    Status: 1,
  },
  {
    CountryId: 116,
    CountryName: "Liberia",
    CN_CountryName: "利比里亚",
    CountryCode: 231,
    ISOCode: "LR",
    Status: 1,
  },
  {
    CountryId: 117,
    CountryName: "Libya",
    CN_CountryName: "利比亚",
    CountryCode: 218,
    ISOCode: "LY",
    Status: 1,
  },
  {
    CountryId: 118,
    CountryName: "Liechtenstein",
    CN_CountryName: "列支敦士登",
    CountryCode: 423,
    ISOCode: "LI",
    Status: 1,
  },
  {
    CountryId: 119,
    CountryName: "Lithuania",
    CN_CountryName: "立陶宛",
    CountryCode: 370,
    ISOCode: "LT",
    Status: 1,
  },
  {
    CountryId: 120,
    CountryName: "Luxembourg",
    CN_CountryName: "卢森堡",
    CountryCode: 352,
    ISOCode: "LU",
    Status: 1,
  },
  {
    CountryId: 121,
    CountryName: "Macau",
    CN_CountryName: "澳门",
    CountryCode: 853,
    ISOCode: "MO",
    Status: 1,
  },
  {
    CountryId: 122,
    CountryName: "Macedonia",
    CN_CountryName: "马其顿",
    CountryCode: 389,
    ISOCode: "MK",
    Status: 1,
  },
  {
    CountryId: 123,
    CountryName: "Madagascar",
    CN_CountryName: "马达加斯加",
    CountryCode: 261,
    ISOCode: "MG",
    Status: 1,
  },
  {
    CountryId: 124,
    CountryName: "Malawi",
    CN_CountryName: "马拉维",
    CountryCode: 265,
    ISOCode: "MW",
    Status: 1,
  },
  {
    CountryId: 125,
    CountryName: "Malaysia",
    CN_CountryName: "马来西亚",
    CountryCode: 60,
    ISOCode: "MY",
    Status: 1,
  },
  {
    CountryId: 126,
    CountryName: "Maldives",
    CN_CountryName: "马尔代夫",
    CountryCode: 960,
    ISOCode: "MV",
    Status: 1,
  },
  {
    CountryId: 127,
    CountryName: "Mali",
    CN_CountryName: "马里",
    CountryCode: 223,
    ISOCode: "ML",
    Status: 1,
  },
  {
    CountryId: 128,
    CountryName: "Malta",
    CN_CountryName: "马耳他",
    CountryCode: 356,
    ISOCode: "MT",
    Status: 1,
  },
  {
    CountryId: 129,
    CountryName: "Marshall Islands",
    CN_CountryName: "马绍尔群岛",
    CountryCode: 692,
    ISOCode: "MH",
    Status: 1,
  },
  {
    CountryId: 130,
    CountryName: "Mauritania",
    CN_CountryName: "毛里塔尼亚",
    CountryCode: 222,
    ISOCode: "MR",
    Status: 1,
  },
  {
    CountryId: 131,
    CountryName: "Mauritius",
    CN_CountryName: "毛里求斯",
    CountryCode: 230,
    ISOCode: "MU",
    Status: 1,
  },
  {
    CountryId: 132,
    CountryName: "Mayotte",
    CN_CountryName: "马约特",
    CountryCode: 262,
    ISOCode: "YT",
    Status: 1,
  },
  {
    CountryId: 133,
    CountryName: "Mexico",
    CN_CountryName: "墨西哥",
    CountryCode: 52,
    ISOCode: "MX",
    Status: 1,
  },
  {
    CountryId: 134,
    CountryName: "Micronesia",
    CN_CountryName: "密克罗尼西亚",
    CountryCode: 691,
    ISOCode: "FM",
    Status: 1,
  },
  {
    CountryId: 135,
    CountryName: "Moldova",
    CN_CountryName: "摩尔多瓦",
    CountryCode: 373,
    ISOCode: "MD",
    Status: 1,
  },
  {
    CountryId: 136,
    CountryName: "Monaco",
    CN_CountryName: "摩纳哥",
    CountryCode: 377,
    ISOCode: "MC",
    Status: 1,
  },
  {
    CountryId: 137,
    CountryName: "Mongolia",
    CN_CountryName: "蒙古",
    CountryCode: 976,
    ISOCode: "MN",
    Status: 1,
  },
  {
    CountryId: 138,
    CountryName: "Montenegro",
    CN_CountryName: "黑山",
    CountryCode: 382,
    ISOCode: "ME",
    Status: 1,
  },
  {
    CountryId: 139,
    CountryName: "Montserrat",
    CN_CountryName: "蒙特塞拉特",
    CountryCode: 1664,
    ISOCode: "MS",
    Status: 1,
  },
  {
    CountryId: 140,
    CountryName: "Morocco",
    CN_CountryName: "摩洛哥",
    CountryCode: 212,
    ISOCode: "MA",
    Status: 1,
  },
  {
    CountryId: 141,
    CountryName: "Mozambique",
    CN_CountryName: "莫桑比克",
    CountryCode: 258,
    ISOCode: "MZ",
    Status: 1,
  },
  {
    CountryId: 142,
    CountryName: "Namibia",
    CN_CountryName: "纳米比亚",
    CountryCode: 264,
    ISOCode: "NA",
    Status: 1,
  },
  {
    CountryId: 143,
    CountryName: "Nauru",
    CN_CountryName: "瑙鲁",
    CountryCode: 674,
    ISOCode: "NR",
    Status: 1,
  },
  {
    CountryId: 144,
    CountryName: "Nepal",
    CN_CountryName: "尼泊尔",
    CountryCode: 977,
    ISOCode: "NP",
    Status: 1,
  },
  {
    CountryId: 145,
    CountryName: "Netherlands",
    CN_CountryName: "荷兰",
    CountryCode: 31,
    ISOCode: "NL",
    Status: 1,
  },
  {
    CountryId: 146,
    CountryName: "Netherlands Antilles",
    CN_CountryName: "荷兰安的列斯群岛",
    CountryCode: 599,
    ISOCode: "AN",
    Status: 1,
  },
  {
    CountryId: 147,
    CountryName: "New Caledonia",
    CN_CountryName: "新喀里多尼亚",
    CountryCode: 687,
    ISOCode: "NC",
    Status: 1,
  },
  {
    CountryId: 148,
    CountryName: "New Zealand",
    CN_CountryName: "新西兰",
    CountryCode: 64,
    ISOCode: "NZ",
    Status: 1,
  },
  {
    CountryId: 149,
    CountryName: "Nicaragua",
    CN_CountryName: "尼加拉瓜",
    CountryCode: 505,
    ISOCode: "NI",
    Status: 1,
  },
  {
    CountryId: 150,
    CountryName: "Niger",
    CN_CountryName: "尼日尔",
    CountryCode: 227,
    ISOCode: "NE",
    Status: 1,
  },
  {
    CountryId: 151,
    CountryName: "Nigeria",
    CN_CountryName: "尼日利亚",
    CountryCode: 234,
    ISOCode: "NG",
    Status: 1,
  },
  {
    CountryId: 152,
    CountryName: "Niue",
    CN_CountryName: "纽埃",
    CountryCode: 683,
    ISOCode: "NU",
    Status: 1,
  },
  {
    CountryId: 153,
    CountryName: "Norfolk Island",
    CN_CountryName: "诺福克岛",
    CountryCode: 672,
    ISOCode: "NF",
    Status: 1,
  },
  {
    CountryId: 154,
    CountryName: "North Korea",
    CN_CountryName: "朝鲜",
    CountryCode: 850,
    ISOCode: "KP",
    Status: 1,
  },
  {
    CountryId: 155,
    CountryName: "Northern Mariana Islands",
    CN_CountryName: "北马里亚纳群岛",
    CountryCode: 1670,
    ISOCode: "MP",
    Status: 1,
  },
  {
    CountryId: 156,
    CountryName: "Norway",
    CN_CountryName: "挪威",
    CountryCode: 47,
    ISOCode: "NO",
    Status: 1,
  },
  {
    CountryId: 157,
    CountryName: "Oman",
    CN_CountryName: "阿曼",
    CountryCode: 968,
    ISOCode: "OM",
    Status: 1,
  },
  {
    CountryId: 158,
    CountryName: "Pakistan",
    CN_CountryName: "巴基斯坦",
    CountryCode: 92,
    ISOCode: "PK",
    Status: 1,
  },
  {
    CountryId: 159,
    CountryName: "Palau",
    CN_CountryName: "帕劳",
    CountryCode: 680,
    ISOCode: "PW",
    Status: 1,
  },
  {
    CountryId: 160,
    CountryName: "Panama",
    CN_CountryName: "巴拿马",
    CountryCode: 507,
    ISOCode: "PA",
    Status: 1,
  },
  {
    CountryId: 161,
    CountryName: "Papua New Guinea",
    CN_CountryName: "巴布亚新几内亚",
    CountryCode: 675,
    ISOCode: "PG",
    Status: 1,
  },
  {
    CountryId: 162,
    CountryName: "Paraguay",
    CN_CountryName: "巴拉圭",
    CountryCode: 595,
    ISOCode: "PY",
    Status: 1,
  },
  {
    CountryId: 163,
    CountryName: "Peru",
    CN_CountryName: "秘鲁",
    CountryCode: 51,
    ISOCode: "PE",
    Status: 1,
  },
  {
    CountryId: 164,
    CountryName: "Philippines",
    CN_CountryName: "菲律宾",
    CountryCode: 63,
    ISOCode: "PH",
    Status: 1,
  },
  {
    CountryId: 165,
    CountryName: "Pitcairn Islands",
    CN_CountryName: "皮特凯恩群岛",
    CountryCode: 870,
    ISOCode: "PN",
    Status: 1,
  },
  {
    CountryId: 166,
    CountryName: "Poland",
    CN_CountryName: "波兰",
    CountryCode: 48,
    ISOCode: "PL",
    Status: 1,
  },
  {
    CountryId: 167,
    CountryName: "Portugal",
    CN_CountryName: "葡萄牙",
    CountryCode: 351,
    ISOCode: "PT",
    Status: 1,
  },
  {
    CountryId: 168,
    CountryName: "Puerto Rico",
    CN_CountryName: "波多黎各",
    CountryCode: 1,
    ISOCode: "PR",
    Status: 1,
  },
  {
    CountryId: 169,
    CountryName: "Qatar",
    CN_CountryName: "卡塔尔",
    CountryCode: 974,
    ISOCode: "QA",
    Status: 1,
  },
  {
    CountryId: 170,
    CountryName: "Republic of the Congo",
    CN_CountryName: "刚果共和国",
    CountryCode: 242,
    ISOCode: "CG",
    Status: 1,
  },
  {
    CountryId: 171,
    CountryName: "Romania",
    CN_CountryName: "罗马尼亚",
    CountryCode: 40,
    ISOCode: "RO",
    Status: 1,
  },
  {
    CountryId: 172,
    CountryName: "Russia",
    CN_CountryName: "俄罗斯",
    CountryCode: 7,
    ISOCode: "RU",
    Status: 1,
  },
  {
    CountryId: 173,
    CountryName: "Rwanda",
    CN_CountryName: "卢旺达",
    CountryCode: 250,
    ISOCode: "RW",
    Status: 1,
  },
  {
    CountryId: 174,
    CountryName: "Saint Barthelemy",
    CN_CountryName: "圣巴泰勒米",
    CountryCode: 590,
    ISOCode: "BL",
    Status: 1,
  },
  {
    CountryId: 175,
    CountryName: "Saint Helena",
    CN_CountryName: "圣赫勒拿",
    CountryCode: 290,
    ISOCode: "SH",
    Status: 1,
  },
  {
    CountryId: 176,
    CountryName: "Saint Kitts and Nevis",
    CN_CountryName: "圣基茨和尼维斯",
    CountryCode: 1869,
    ISOCode: "KN",
    Status: 1,
  },
  {
    CountryId: 177,
    CountryName: "Saint Lucia",
    CN_CountryName: "圣卢西亚",
    CountryCode: 1758,
    ISOCode: "LC",
    Status: 1,
  },
  {
    CountryId: 178,
    CountryName: "Saint Martin",
    CN_CountryName: "圣马丁",
    CountryCode: 1599,
    ISOCode: "MF",
    Status: 1,
  },
  {
    CountryId: 179,
    CountryName: "Saint Pierre and Miquelon",
    CN_CountryName: "圣皮埃尔和密克隆",
    CountryCode: 508,
    ISOCode: "PM",
    Status: 1,
  },
  {
    CountryId: 180,
    CountryName: "Saint Vincent and the Grenadines",
    CN_CountryName: "圣文森特和格林纳丁斯",
    CountryCode: 1784,
    ISOCode: "VC",
    Status: 1,
  },
  {
    CountryId: 181,
    CountryName: "Samoa",
    CN_CountryName: "萨摩亚",
    CountryCode: 685,
    ISOCode: "WS",
    Status: 1,
  },
  {
    CountryId: 182,
    CountryName: "San Marino",
    CN_CountryName: "马里诺",
    CountryCode: 378,
    ISOCode: "SM",
    Status: 1,
  },
  {
    CountryId: 183,
    CountryName: "Sao Tome and Principe",
    CN_CountryName: "圣多美和普林西比",
    CountryCode: 239,
    ISOCode: "ST",
    Status: 1,
  },
  {
    CountryId: 184,
    CountryName: "Saudi Arabia",
    CN_CountryName: "沙特阿拉伯",
    CountryCode: 966,
    ISOCode: "SA",
    Status: 1,
  },
  {
    CountryId: 185,
    CountryName: "Senegal",
    CN_CountryName: "塞内加尔",
    CountryCode: 221,
    ISOCode: "SN",
    Status: 1,
  },
  {
    CountryId: 186,
    CountryName: "Serbia",
    CN_CountryName: "塞尔维亚",
    CountryCode: 381,
    ISOCode: "RS",
    Status: 1,
  },
  {
    CountryId: 187,
    CountryName: "Seychelles",
    CN_CountryName: "塞舌尔",
    CountryCode: 248,
    ISOCode: "SC",
    Status: 1,
  },
  {
    CountryId: 188,
    CountryName: "Sierra Leone",
    CN_CountryName: "塞拉利昂",
    CountryCode: 232,
    ISOCode: "SL",
    Status: 1,
  },
  {
    CountryId: 189,
    CountryName: "Singapore",
    CN_CountryName: "新加坡",
    CountryCode: 65,
    ISOCode: "SG",
    Status: 1,
  },
  {
    CountryId: 190,
    CountryName: "Slovakia",
    CN_CountryName: "斯洛伐克",
    CountryCode: 421,
    ISOCode: "SK",
    Status: 1,
  },
  {
    CountryId: 191,
    CountryName: "Slovenia",
    CN_CountryName: "斯洛文尼亚",
    CountryCode: 386,
    ISOCode: "SI",
    Status: 1,
  },
  {
    CountryId: 192,
    CountryName: "Solomon Islands",
    CN_CountryName: "所罗门群岛",
    CountryCode: 677,
    ISOCode: "SB",
    Status: 1,
  },
  {
    CountryId: 193,
    CountryName: "Somalia",
    CN_CountryName: "索马里",
    CountryCode: 252,
    ISOCode: "SO",
    Status: 1,
  },
  {
    CountryId: 194,
    CountryName: "South Africa",
    CN_CountryName: "南非",
    CountryCode: 27,
    ISOCode: "ZA",
    Status: 1,
  },
  {
    CountryId: 195,
    CountryName: "South Korea",
    CN_CountryName: "韩国",
    CountryCode: 82,
    ISOCode: "KR",
    Status: 1,
  },
  {
    CountryId: 196,
    CountryName: "Spain",
    CN_CountryName: "西班牙",
    CountryCode: 34,
    ISOCode: "ES",
    Status: 1,
  },
  {
    CountryId: 197,
    CountryName: "Sri Lanka",
    CN_CountryName: "斯里兰卡",
    CountryCode: 94,
    ISOCode: "LK",
    Status: 1,
  },
  {
    CountryId: 198,
    CountryName: "Sudan",
    CN_CountryName: "苏丹",
    CountryCode: 249,
    ISOCode: "SD",
    Status: 1,
  },
  {
    CountryId: 199,
    CountryName: "Suriname",
    CN_CountryName: "苏里南",
    CountryCode: 597,
    ISOCode: "SR",
    Status: 1,
  },
  {
    CountryId: 200,
    CountryName: "Swaziland",
    CN_CountryName: "斯威士兰",
    CountryCode: 268,
    ISOCode: "SZ",
    Status: 1,
  },
  {
    CountryId: 201,
    CountryName: "Sweden",
    CN_CountryName: "瑞典",
    CountryCode: 46,
    ISOCode: "SE",
    Status: 1,
  },
  {
    CountryId: 202,
    CountryName: "Switzerland",
    CN_CountryName: "瑞士",
    CountryCode: 41,
    ISOCode: "CH",
    Status: 1,
  },
  {
    CountryId: 203,
    CountryName: "Syria",
    CN_CountryName: "叙利亚",
    CountryCode: 963,
    ISOCode: "SY",
    Status: 1,
  },
  {
    CountryId: 204,
    CountryName: "Taiwan",
    CN_CountryName: "台湾",
    CountryCode: 886,
    ISOCode: "TW",
    Status: 1,
  },
  {
    CountryId: 205,
    CountryName: "Tajikistan",
    CN_CountryName: "塔吉克斯坦",
    CountryCode: 992,
    ISOCode: "TJ",
    Status: 1,
  },
  {
    CountryId: 206,
    CountryName: "Tanzania",
    CN_CountryName: "坦桑尼亚",
    CountryCode: 255,
    ISOCode: "TZ",
    Status: 1,
  },
  {
    CountryId: 207,
    CountryName: "Thailand",
    CN_CountryName: "泰国",
    CountryCode: 66,
    ISOCode: "TH",
    Status: 1,
  },
  {
    CountryId: 208,
    CountryName: "Timor-Leste",
    CN_CountryName: "东帝汶",
    CountryCode: 670,
    ISOCode: "TL",
    Status: 1,
  },
  {
    CountryId: 209,
    CountryName: "Togo",
    CN_CountryName: "多哥",
    CountryCode: 228,
    ISOCode: "TG",
    Status: 1,
  },
  {
    CountryId: 210,
    CountryName: "Tokelau",
    CN_CountryName: "托克劳",
    CountryCode: 690,
    ISOCode: "TK",
    Status: 1,
  },
  {
    CountryId: 211,
    CountryName: "Tonga",
    CN_CountryName: "汤加",
    CountryCode: 676,
    ISOCode: "TO",
    Status: 1,
  },
  {
    CountryId: 212,
    CountryName: "Trinidad and Tobago",
    CN_CountryName: "特立尼达和多巴哥",
    CountryCode: 1868,
    ISOCode: "TT",
    Status: 1,
  },
  {
    CountryId: 213,
    CountryName: "Tunisia",
    CN_CountryName: "突尼斯",
    CountryCode: 216,
    ISOCode: "TN",
    Status: 1,
  },
  {
    CountryId: 214,
    CountryName: "Turkey",
    CN_CountryName: "固键",
    CountryCode: 90,
    ISOCode: "TR",
    Status: 1,
  },
  {
    CountryId: 215,
    CountryName: "Turkmenistan",
    CN_CountryName: "土库曼斯坦",
    CountryCode: 993,
    ISOCode: "TM",
    Status: 1,
  },
  {
    CountryId: 216,
    CountryName: "Turks and Caicos Islands",
    CN_CountryName: "特克斯和凯科斯群岛",
    CountryCode: 1649,
    ISOCode: "TC",
    Status: 1,
  },
  {
    CountryId: 217,
    CountryName: "Tuvalu",
    CN_CountryName: "图瓦卢",
    CountryCode: 688,
    ISOCode: "TV",
    Status: 1,
  },
  {
    CountryId: 218,
    CountryName: "Uganda",
    CN_CountryName: "乌干达",
    CountryCode: 256,
    ISOCode: "UG",
    Status: 1,
  },
  {
    CountryId: 219,
    CountryName: "Ukraine",
    CN_CountryName: "乌克兰",
    CountryCode: 380,
    ISOCode: "UA",
    Status: 1,
  },
  {
    CountryId: 220,
    CountryName: "United Arab Emirates",
    CN_CountryName: "阿拉伯联合酋长国",
    CountryCode: 971,
    ISOCode: "AE",
    Status: 1,
  },
  {
    CountryId: 221,
    CountryName: "United Kingdom",
    CN_CountryName: "英国",
    CountryCode: 44,
    ISOCode: "GB",
    Status: 1,
  },
  {
    CountryId: 222,
    CountryName: "United States",
    CN_CountryName: "美国",
    CountryCode: 1,
    ISOCode: "US",
    Status: 1,
  },
  {
    CountryId: 223,
    CountryName: "Uruguay",
    CN_CountryName: "乌拉圭",
    CountryCode: 598,
    ISOCode: "UY",
    Status: 1,
  },
  {
    CountryId: 224,
    CountryName: "US Virgin Islands",
    CN_CountryName: "美属维尔京群岛",
    CountryCode: 1340,
    ISOCode: "UM",
    Status: 1,
  },
  {
    CountryId: 225,
    CountryName: "Uzbekistan",
    CN_CountryName: "乌兹别克斯坦",
    CountryCode: 998,
    ISOCode: "UZ",
    Status: 1,
  },
  {
    CountryId: 226,
    CountryName: "Vanuatu",
    CN_CountryName: "瓦努阿图",
    CountryCode: 678,
    ISOCode: "VU",
    Status: 1,
  },
  {
    CountryId: 227,
    CountryName: "Venezuela",
    CN_CountryName: "委内瑞拉",
    CountryCode: 58,
    ISOCode: "VE",
    Status: 1,
  },
  {
    CountryId: 228,
    CountryName: "Vietnam",
    CN_CountryName: "越南",
    CountryCode: 84,
    ISOCode: "VN",
    Status: 1,
  },
  {
    CountryId: 229,
    CountryName: "Wallis and Futuna",
    CN_CountryName: "瓦利斯和富图纳",
    CountryCode: 681,
    ISOCode: "WF",
    Status: 1,
  },
  {
    CountryId: 230,
    CountryName: "West Bank",
    CN_CountryName: "西岸",
    CountryCode: 970,
    ISOCode: "PS",
    Status: 1,
  },
  {
    CountryId: 231,
    CountryName: "Yemen",
    CN_CountryName: "也门",
    CountryCode: 967,
    ISOCode: "YE",
    Status: 1,
  },
  {
    CountryId: 232,
    CountryName: "Zambia",
    CN_CountryName: "赞比亚",
    CountryCode: 260,
    ISOCode: "ZM",
    Status: 1,
  },
  {
    CountryId: 233,
    CountryName: "Zimbabwe",
    CN_CountryName: "津巴布韦",
    CountryCode: 263,
    ISOCode: "ZW",
    Status: 1,
  },
];
