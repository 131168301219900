import { AUTH_TOKEN } from "../config/constants";

export const checkAuth = (type) => {
  if (
    localStorage.getItem("admin") === "true" ||
    (localStorage.getItem("callcenter") === "true" && type === "create")
  ) {
    return true;
  }
  return true;
};

export const isExpire = () => {
  var now = Date.now();
  var token_expire = new Date(localStorage.getItem("expires_in"));
  if (token_expire > now) {
    return false;
  }
  return true;
};

export const expireDate = (num) => {
  num = num * 1000;
  var now = Date.now();
  num = now + num;
  var date = new Date(num);
  return date.toString();
};

export const isAuthenticated = () => {
  if (localStorage.getItem(AUTH_TOKEN)) {
    if (!isExpire()) {
      return true;
    }
  }
  return false;
};
