import React from "react";
import "../assets/scss/GuestList.scss";
import { TableSortLabel } from "@material-ui/core";

const TableLabelToggle = ({
  colState,
  setColState,
  handleColSort,
  label,
  fieldName,
  stateName,
}) => {
  return (
    <TableSortLabel
      active={true}
      direction={colState[stateName] ? colState[stateName] : "asc"}
      onClick={() => {
        setColState({
          ...colState,
          [stateName]:
            colState[stateName] && colState[stateName] === "desc"
              ? "asc"
              : "desc",
        });
        handleColSort(fieldName, stateName);
      }}
    >
      {label}
    </TableSortLabel>
  );
};

export default TableLabelToggle;
