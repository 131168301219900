import { gql } from "apollo-boost";

const LOGIN_QUERY = gql`
  mutation($input: LoginInput) {
    login(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

export { LOGIN_QUERY };
