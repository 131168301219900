import { gql } from "apollo-boost";

const CREATE_GUEST = gql`
  mutation($input: passengerActivityInput) {
    createGuest(input: $input) {
      id
      company {
        id
        company_type_id
        company_type {
          id
          name
        }
        name
        phone
        email
        note
      }
      passenger {
        id
        country {
          id
          name
        }
        title
        first_name
        last_name
        phone_prefix
        phone
        email
        passport
        passport_exp
        nrc
        date_of_birth
        father_name
        occupation
        photo
        note
        created_at
      }
      recordType {
        id
        name
      }
      visa {
        id
        visaType {
          id
          name
        }
        visa_number
        visa_expiry
        arrival_date
        full_address
      }
    }
  }
`;

export { CREATE_GUEST };
