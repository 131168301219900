import React from "react";
import "./App.css";
import "./assets/scss/Common.scss";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  LOGIN_TYPE,
} from "./config/constants";
import { createUploadLink } from "apollo-upload-client";
import Login from "./pages/Login";
import ReportGuestList from "./pages/ReportGuestList";
import DepartmentGuestList from "./pages/DepartmentGuestList";
import HotelGuestList from "./pages/HotelGuestList";

const authLink = setContext((_, { headers }) => {
  const tokenType = localStorage.getItem(TOKEN_TYPE);
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `${tokenType} ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(
            ({ debugMessage, message, locations, path }) => {
              if (debugMessage && debugMessage === "Unauthenticated.") {
                localStorage.removeItem(AUTH_TOKEN);
                window.location.href = "/";
              }
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              );
            }
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      createUploadLink({
        uri:
          process.env.REACT_APP_BACKEND_SERVER +
          process.env.REACT_APP_GRAPHQL_SERVER,
      }),
    ])
  ),
  cache: new InMemoryCache(),
});

const PrivateRouteHotel = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(LOGIN_USER_ID) &&
        localStorage.getItem(AUTH_TOKEN) &&
        localStorage.getItem(LOGIN_TYPE) === "hotel" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/hotel-login" />
        )
      }
    />
  );
};

const PrivateRouteDept = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(LOGIN_USER_ID) &&
        localStorage.getItem(AUTH_TOKEN) &&
        localStorage.getItem(LOGIN_TYPE) === "department" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/hotel-login" component={Login} />
          <PrivateRouteHotel exact path="/report" component={ReportGuestList} />
          <PrivateRouteDept
            exact
            path="/department/guest-list"
            component={DepartmentGuestList}
          />
          <PrivateRouteHotel
            exact
            path="/hotel/guest-list"
            component={HotelGuestList}
          />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
