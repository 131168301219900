import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import "../assets/scss/Login.scss";
import Logo from "../assets/images/moht-logo.png";
import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  LOGIN_TYPE,
} from "../config/constants";
import { expireDate } from "../lib/auth";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN_QUERY } from "../queries/LoginQuery";

const Login = (props) => {
  const [phoneNo, setPhoneNo] = useState("09");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);

  let isNum = (num) => {
    return !/[^.[0-9]]*/.test(num);
  };

  let isValidateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const [createLoginHotel] = useMutation(LOGIN_QUERY, {
    onCompleted: (response) => {
      localStorage.setItem(AUTH_TOKEN, response.login.access_token);
      localStorage.setItem(TOKEN_TYPE, response.login.token_type);
      localStorage.setItem("expires_in", expireDate(response.login.expires_in));
      localStorage.setItem("identifier", response.login.user.email);
      localStorage.setItem(LOGIN_USER_ID, response.login.user.id);
      localStorage.setItem(LOGIN_TYPE, "hotel");

      window.location.href = "/report";
    },
    onError: (err) => {
      console.log(err);
      setError("The user credentials were incorrect.");
      setIsSpinner(false);
    },
  });

  const checkAuthHotel = () => {
    email.length === 0 && setEmailError("Email is required.");
    password.length === 0 && setPasswordError("Password is required.");
    email.length > 0 &&
      !isValidateEmail(email) &&
      setEmailError("Email is invalid.");

    if (email.length > 0 && password.length > 0 && isValidateEmail(email)) {
      setIsSpinner(true);
      createLoginHotel({
        variables: {
          input: { username: email, password },
        },
      });
    }
  };

  const [createLoginDept] = useMutation(LOGIN_QUERY, {
    onCompleted: (response) => {
      localStorage.setItem(AUTH_TOKEN, response.login.access_token);
      localStorage.setItem(TOKEN_TYPE, response.login.token_type);
      localStorage.setItem("expires_in", expireDate(response.login.expires_in));
      localStorage.setItem("identifier", response.login.user.email);
      localStorage.setItem(LOGIN_USER_ID, response.login.user.id);
      localStorage.setItem(LOGIN_TYPE, "department");

      window.location.href = "/department/guest-list";
    },
    onError: (err) => {
      console.log(err);
      setError("The user credentials were incorrect.");
      setIsSpinner(false);
    },
  });

  const checkAuthDept = () => {
    email.length === 0 && setEmailError("Email is required.");
    password.length === 0 && setPasswordError("Password is required.");
    email.length > 0 &&
      !isValidateEmail(email) &&
      setEmailError("Email is invalid.");

    if (email.length > 0 && password.length > 0 && isValidateEmail(email)) {
      setIsSpinner(true);
      createLoginDept({
        variables: {
          input: { username: email, password },
        },
      });
    }
  };

  let currentUrl = props.location.pathname;

  return (
    <>
      <Container className="login-bg">
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12} className="login-container">
            <div className="login-wrapper">
              <div
                className={`title ${currentUrl !== "/" ? "hotel-title" : ""}`}
              >
                <img src={Logo} alt="Logo" />
                <span> Hotel Guest List Management System</span>
              </div>
              {currentUrl === "/" && (
                <>
                  <div className="login-form">
                    <h5> Department User Login</h5>
                    <form>
                      <TextField
                        id="standard-basic"
                        type="email"
                        className={`hotel-input ${
                          error !== "" || emailError !== "" ? "error-input" : ""
                        }`}
                        label=""
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                        onFocus={() => {
                          setEmailError("");
                          setError("");
                        }}
                      />
                      {emailError !== "" && (
                        <span className="error-text">{emailError}</span>
                      )}

                      <TextField
                        id="standard-basic"
                        type="password"
                        className={`hotel-input ${
                          error !== "" || passwordError !== ""
                            ? "error-input"
                            : ""
                        }`}
                        label=""
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError("");
                        }}
                        onFocus={() => {
                          setPasswordError("");
                          setError("");
                        }}
                      />
                      {passwordError !== "" && (
                        <span className="error-text">{passwordError}</span>
                      )}

                      {error !== "" && (
                        <span className="error-text">{error}</span>
                      )}
                    </form>
                  </div>
                  <div className="button-div">
                    <Button variant="text" onClick={checkAuthDept}>
                      LOGIN {isSpinner && <CircularProgress />}
                    </Button>
                  </div>
                  <div className="login-footer">
                    <p className="mm-font video-link">
                      <a
                        href="https://bit.ly/yrg-glist-video"
                        target="_blank"
                        rel="noreferrer"
                      >
                        အသုံးပြုနည်း Video
                      </a>
                    </p>
                    <p className="copyright">
                      © 2020 Ministry of Hotels &amp; Tourism
                    </p>
                  </div>
                </>
              )}

              {currentUrl === "/hotel-login" && (
                <>
                  <div className="login-form">
                    <form>
                      <TextField
                        id="standard-basic"
                        type="email"
                        className={`hotel-input ${
                          error !== "" || emailError !== "" ? "error-input" : ""
                        }`}
                        label=""
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                        onFocus={() => {
                          setEmailError("");
                          setError("");
                        }}
                      />
                      {emailError !== "" && (
                        <span className="error-text">{emailError}</span>
                      )}

                      <TextField
                        id="standard-basic"
                        type="password"
                        className={`hotel-input ${
                          error !== "" || passwordError !== ""
                            ? "error-input"
                            : ""
                        }`}
                        label=""
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError("");
                        }}
                        onFocus={() => {
                          setPasswordError("");
                          setError("");
                        }}
                      />
                      {passwordError !== "" && (
                        <span className="error-text">{passwordError}</span>
                      )}

                      {error !== "" && (
                        <span className="error-text">{error}</span>
                      )}
                    </form>
                  </div>
                  <div className="button-div">
                    <Button variant="text" onClick={checkAuthHotel}>
                      LOGIN {isSpinner && <CircularProgress />}
                    </Button>
                  </div>

                  <div className="login-footer">
                    <p className="mm-font video-link">
                      <a
                        target="_blank"
                        href="https://bit.ly/yrg-greg-video"
                        rel="noreferrer"
                      >
                        အသုံးပြုနည်း Video
                      </a>
                    </p>
                    <p className="phone-no">067 406062, 067 406476</p>
                    <p className="copyright">
                      © 2020 Ministry of Hotels &amp; Tourism
                    </p>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
