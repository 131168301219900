import React, { useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
} from "@material-ui/core/";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../assets/images/moht-logo.png";
import MediaQuery from "react-responsive";
import { AUTH_TOKEN, LOGIN_TYPE } from "../config/constants";

const Menu = ({ title, name, reportLink, guestLink }) => {
  const [loginType] = useState(localStorage.getItem(LOGIN_TYPE));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Grid container className="menu-container">
      <Grid
        item
        xs={12}
        md={4}
        lg={5}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Grid item>
          <Button onClick={() => toggleDrawer()}>
            <MenuIcon className="side-main-menu-icon" />
          </Button>
        </Grid>
        <Grid item>
          <img src={Logo} alt="Logo" style={{ height: "55px" }} />
        </Grid>
        <Grid item style={{ marginLeft: "15px" }}>
          <h2 style={{ color: "white" }}>{title}</h2>
        </Grid>
      </Grid>
      <MediaQuery minWidth={960}>
        <Grid
          item
          md={8}
          lg={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className={loginType === "hotel" ? "hotel-menu" : "dept-menu"}
        >
          {loginType === "hotel" && (
            <Link className="main-menu-link top-menu" to="#">
              Download Template
            </Link>
          )}
          {loginType === "hotel" && (
            <Link className="main-menu-link top-menu" to={reportLink}>
              Report Guest List
            </Link>
          )}

          <Link className="main-menu-link top-menu" to={guestLink}>
            Hotel Guests Overview
          </Link>
          <Link
            className="main-menu-link top-menu"
            to="#"
            onClick={() => {
              if (localStorage.getItem(AUTH_TOKEN)) {
                localStorage.getItem(LOGIN_TYPE) === "department"
                  ? (window.location.href = "/")
                  : (window.location.href = "/hotel-login");
                localStorage.clear();
              }
            }}
          >
            Logout
          </Link>
        </Grid>
      </MediaQuery>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer()}
        className="side-main-menu-drawer"
      >
        <List>
          <ListItem>
            <img src={Logo} alt="Logo" />
          </ListItem>
          <ListItem
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {name}
          </ListItem>
          <Divider />
          {loginType === "hotel" && (
            <ListItem onClick={() => toggleDrawer()}>
              <Link className="main-menu-link" to={reportLink}>
                Report Guest List
              </Link>
            </ListItem>
          )}

          <ListItem onClick={() => toggleDrawer()}>
            <Link className="main-menu-link" to={guestLink}>
              Hotel Guests Overview
            </Link>
          </ListItem>

          {loginType === "hotel" && (
            <ListItem onClick={() => toggleDrawer()}>
              <Link className="main-menu-link" to="#">
                Download Template
              </Link>
            </ListItem>
          )}
          <ListItem onClick={() => toggleDrawer()}>
            <a
              className="main-menu-link"
              target="_blank"
              rel="noreferrer"
              href="https://s3-ap-southeast-1.amazonaws.com/greg.yangon.gov.mm/manuals/YRG-Glist-Department-Manual-Mobile.mp4"
            >
              Usage Manual Video
            </a>
          </ListItem>
          <Divider />
          <ListItem onClick={() => toggleDrawer()}>
            <Link
              className="main-menu-link"
              to="#"
              onClick={() => {
                if (localStorage.getItem(AUTH_TOKEN)) {
                  localStorage.getItem(LOGIN_TYPE) === "department"
                    ? (window.location.href = "/")
                    : (window.location.href = "/hotel-login");
                  localStorage.clear();
                }
              }}
            >
              Logout
            </Link>
          </ListItem>
          <Divider />
          <ListItem className="version-text">Version</ListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

export default Menu;
