import { gql } from "apollo-boost";

const GET_GUEST_LIST = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    guestList(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        company {
          id
          company_type_id
          company_type {
            id
            name
          }
          name
          phone
          email
          note
          township {
            id
            name
            region {
              id
              name
            }
          }
        }
        passenger {
          id
          country {
            id
            name
          }
          title
          first_name
          last_name
          phone_prefix
          phone
          email
          passport
          passport_exp
          nrc
          date_of_birth
          father_name
          occupation
          photo
          note
          created_at
        }
        recordType {
          id
          name
        }
        visa {
          id
          name
          visaType {
            id
            name
          }
          visa_number
          visa_expiry
          arrival_date
          full_address
        }
        from_date
        to_date
        ref_number
        note
        created_at
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export { GET_GUEST_LIST };
