import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Menu from "../components/Menu";
import "../assets/scss/GuestList.scss";
import { useQuery } from "@apollo/react-hooks";
import { GET_GUEST_LIST } from "../queries/GuestListQuery.js";
import GuestList from "../components/GuestList";

const DepartmentGuestList = () => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [orderByField, setOrderByField] = useState("id");
  const [orderByValue, setOrderByValue] = useState("DESC");

  useEffect(() => {
    setPage(0);
  }, []);

  const { loading, error, data } = useQuery(GET_GUEST_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      filter: filters ? (filters.length > 0 ? filters : []) : [],
      orderBy: [
        {
          field: orderByField ? orderByField : "id",
          order: orderByValue ? orderByValue : "DESC",
        },
      ],
      first: count,
      page: page + 1,
    },
  });

  if (error) return `Error! ${error}`;

  return (
    <Grid container className="guest-list">
      <Menu
        title="Department Guest List"
        name="Random Hotel"
        reportLink={"#"}
        guestLink={"/department/guest-list"}
      />
      <GuestList
        title="department"
        count={count}
        setCount={setCount}
        setFilters={setFilters}
        page={page}
        setPage={setPage}
        setOrderByField={setOrderByField}
        setOrderByValue={setOrderByValue}
        loading={loading}
        data={data ? data.guestList : null}
      />
    </Grid>
  );
};

export default DepartmentGuestList;
